.MapboxMapContainer-Pin {
    width: 2.2em;
    height: 2.2em;
    cursor: pointer;
}
.mapboxgl-popup-content {
    background: #FAFAFA;
    /*
    position: relative;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(0,0,0,.1);
    padding: 10px 10px 15px;
    pointer-events: auto;
     */
}