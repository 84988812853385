.TextInput-SendIcon {
    color: inherit;
    font-size: 1rem;
    line-height: 1.5;
    font-family: inherit;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    fill: rgba(0, 0, 0, 0.87);
}

.TextInput-TextAreaPlaceholder {
    color: #9aa0a6;
    font-size: 13px;
    margin-top: 6px;
    font: 400 13px Roboto,RobotoDraft,Helvetica,Arial,sans-serif;
    max-width: 100%;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    /*position: relative;*/
    text-overflow: ellipsis;
    white-space: nowrap;
    /*bottom: 12px;*/
}

.TextInput-TextArea-Container {
    color: rgba(255, 255, 255, 0.13);
    display: flex;
    position: relative;
    direction: ltr;
    font-family: 'Roboto', arial, sans-serif;
    font-size: 12px;
    padding-top: 0;
}

.TextInput-Container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 0.5em;
    padding-right: 0.5em;
    justify-items: center;
    align-items: center;
    height: 100%;
    /*width: 100%;*/
}

.TextInput-TextArea {
    box-flex: 1;
    flex-grow: 1;
    flex-shrink: 1;
    background-color: transparent;
    border: none;
    display: block;
    font: 400 13px Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
    font-size: 13px;
    line-height: normal;
    height: 24px;
    min-height: 24px;
    line-height: 24px;
    margin: 0;
    outline: none;
    padding: 0;
    resize: none;
    white-space: pre-wrap;
    word-wrap: break-word;
    z-index: 0;
    overflow-y: visible;
    overflow-x: hidden;
    color: rgba(0, 0, 0, 0.87);
    height: 100%;
}

.TextInput-SendButton {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-items: flex-end;
    justify-content: flex-end;
    align-content: flex-end;
    height: 100%;
    flex: 1;
    width: 100%;
    padding-right: 1em;
    padding-top: 6px;
}
.TextInput-HasFocus .TextInput-TextAreaPlaceholder {
    visibility: hidden;
}

.TextInput-TextAreaContainer {
    width: 100%;
}
.TextInput-TextAreaBoxShadow {
    box-shadow:
            0 -3px 4px 0 rgba(0,0,0,0.14);
    /*0 -3px 3px -2px rgba(0,0,0,0.12),*/
    /*0 -3px  8px 0 rgba(0,0,0,0.2);*/
}

.TextInput-TextAreaBoxShadowLite {
    box-shadow:
            0 -1px 1px 0 rgba(0,0,0,0.14);
    /*0 -3px 3px -2px rgba(0,0,0,0.12),*/
    /*0 -3px  8px 0 rgba(0,0,0,0.2);*/
}

.DarkTheme .TextInput-TextArea {
    color: rgba(255, 255, 255, 0.87) !important;
}

.DarkTheme .TextInput-SendIcon {
    fill: rgba(255, 255, 255, 0.87);
}

.TextInput-HasFocus {
    /*box-shadow:*/
    /*        0 3px 4px 0 rgba(0,0,0,0.14),*/
    /*        0 3px 3px -2px rgba(0,0,0,0.12),*/
    /*        0 1px  8px 0 rgba(0,0,0,0.2);*/
}
