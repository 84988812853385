.LicensePlateCard-Container {
    display: flex;
    flex-direction: row;
}

.LicensePlateCard-Left {
    display: flex;
    flex-direction: column;
    margin-right: 1em;
}

.LicensePlateCard-LiveImage {
    height: 100px;
    max-height: 100px
}

.LicensePlateCard-LeftDataContainer {
    display: flex;
    flex-direction: row;
}

.LicensePlateCard-LeftDataHeadersContainer {
    display: flex;
    flex-direction: column;
    padding-right: 1em;
}
.LicensePlateCard-LeftDataValuesContainer {
    display: flex;
    flex-direction: column;
    font-weight: bold;
}
.LicensePlateCard-RecognizerName {
    display: flex;
    flex-direction: column-reverse;
    flex: 3;
}

.LicensePlateCard-Right {
    display: flex;
    flex-direction: column;
    flex: 3;
}

.LicensePlateCard-RightDataContainer-NoMatch,
.LicensePlateCard-RightDataContainer {
    display: flex;
    flex-direction: row;
}
.LicensePlateCard-RightDataContainer-NoMatch {
    padding-right: 1em;
}
.LicensePlateCard-RightDataHeadersContainer {
    display: flex;
    flex-direction: column;
    flex: 2;
    padding-right: 1em;
    min-width: 6em;
}
.LicensePlateCard-RightDataValuesContainer {
    display: flex;
    flex-direction: column;
    font-weight: bold;
}

.LicensePlateCard-Disposition-Derogatory,
.LicensePlateCard-Disposition {
    font-weight: bold;
}

.LicensePlateCard-Disposition-Derogatory {
    color: red;
}