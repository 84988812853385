.PassportCard-Container {
    display: flex;
    flex-direction: column;
}

.PassportCard-Top {
    display: flex;
    flex-direction: row;
}

.PassportCard-Top > div {
    padding: 1em;
    max-width: 110px;
}

.PassportCard-Top * {
    font-weight: bold;
}


.PassportCard-Top > div:nth-child(1),
.PassportCard-Top > div:nth-child(2) {
    border-right: none;
}

.PassportCard-Top img {
    max-height: 100px;
    max-width: 100px;
}


.PassportCard-DataField-Red {
    color: red
}

.PassportCard-DocumentColumn {
    display: flex;
    flex-direction: column;
    min-width: 10em;
}

.PassportCard-LiveColumn {
    display: flex;
    flex-direction: column;
}

.PassportCard-LiveColumn-Hidden {
    visibility: hidden;
}