/*.callapp_button, .callapp_address {*/
/*    display: none;*/
/*}*/

.callapp-remote-video-text-div {
    display: none;
}.

.callapp video {
    width: 100%;
}

.AWebRTCWrapper-Header {
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    justify-items: center;
    align-items: center;
    width: 100%;
    padding: 12px 0px 12px 0.5em;
}

.AWebRTCWrapper-Hidden {
    display: none;
    visibility: hidden;
    position: absolute;
    left: -10000px;
    right: -10000px;
}

.DarkTheme .AWebRTCWrapper-Header {
    color: rgba(255, 255, 255, 0.87) !important;
}
