.DashboardMapHeader {
  background-color: #004477;
  color: white;
  flex: 1;
  font-size: 1.5em;
  padding: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.DashboardMapHeader .DashboardMapHeader-Name {

}

.DashboardMapHeader .DashboardMapHeader-FirstName,
.DashboardMapHeader .DashboardMapHeader-LastName {
  text-transform: capitalize;
}
