body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  overscroll-behavior: none;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  padding: 0px;
  margin: 0px;
}
