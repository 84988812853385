.ChatBox-NameTimeContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: left;
}
.ChatBox-DisplayName {
    color: #202124;
    /*color: #DFDEDB;*/
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    line-height: 19px;
    padding-right: 8px;
    word-wrap: break-word;
}

.ChatBox-DisplayTime {
    color: #5f6368;
    /*color: #A09C97;*/
    display: inline-block;
    font-size: 12px;
}
.ChatBox-Text {
    color: #202124;
    /*color: #DFDEDB;*/
    font-size: 13px;
    line-height: 20px;
    padding-top: 10px;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.ChatBox-Text-Container {
    display: flex;
    max-width: 100%;
    flex-direction: column;

}
.ChatBox-Message {
    display: flex;
    padding: 14px 24px;
    flex-direction: column;
}
.ChatBox-Container {
    border-bottom: 1px solid #f1f3f4;
    box-sizing: border-box;
    -moz-box-flex: 1;
    flex: 1;
    margin: -1px 0;
    overflow-y: auto;
    padding-bottom: 8px;
    -moz-user-select: text;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.ChatBox-Message-SameAuthor {

}


.DarkTheme .ChatBox-Container {
    border-bottom: 1px solid #0E0C0B !important;
}
.DarkTheme .ChatBox-Text {
    color: #DFDEDB !important;
}

.DarkTheme .ChatBox-DisplayTime {
    color: #A09C97 !important;
}

.DarkTheme .ChatBox-DisplayName {
    color: #DFDEDB !important;
}

.ChatBox-TextInput-Container {
    /*width: 100%;*/
}
