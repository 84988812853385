.DashboardMap {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;

}

.DashboardMap-Header {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.DashboardMap-Panels {
  display: flex;
  flex-direction: row;
  flex: 14
}

.DashboardMap-LeftPanel {
  display: flex;
  flex-direction: column;
  flex: 7;
}
.DashboardMap-LeftPanel-Top {
  display: flex;
  flex: 1;
}

.DashboardMap-RightPanel {
  display: flex;
  flex-direction: column;
  flex: 3;
  background-color: rgba(255,255,255,0.86);
}

.DashboardMap-RightPanel-Top {
  display: flex;
}

.DashboardMap-RightPanel-Bottom {
  display: flex;
  flex: 1;
  overflow: scroll;
}


.DarkTheme .DashboardMap-RightPanel {
  background-color: rgba(0,0,0,0.86) !important;
}
