.PersonCard-Container {
    display: flex;
    flex-direction: row;
}

.PersonCard-Left {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.PersonCard-LiveImage {
    height: 150px;
    max-height: 150px;
    flex: 1;
}

.PersonCard-LiveImageCaption {
    flex: 2;
    display: flex;
    flex-direction: column-reverse;
}

.PersonCard-Middle {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 1em;
    margin-right: 1em;
}

.PersonCard-SourceImage {
    padding-right: 1em;
    height: 150px;
    min-height: 150px;
}

.PersonCard-SourceAndDate {
    font-weight: bold;
}

.PersonCard-Right {
    display: flex;
    flex-direction: column;
    flex: 3;
}

.PersonCard-RightDataContainer-NoMatch,
.PersonCard-RightDataContainer {
    display: flex;
    flex-direction: row;;
}

.PersonCard-RightDataContainer-NoMatch {
    padding-left: 1em;
    padding-right: 1em;
    flex: 1;
}


.PersonCard-RightDataHeadersContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-right: 1em;
    min-width: 6em;
}

.PersonCard-RightDataValuesContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    font-weight: bold;
}

.PersonCard-RecognizerName {
    display: flex;
    flex-direction: column-reverse;
    flex: 3;
}

.PersonCard-Disposition-Derogatory,
.PersonCard-Disposition {
    font-weight: bold;
}

.PersonCard-Disposition-Derogatory {
    color: red;
}

.PersonCard-NameValue,
.PersonCard-AgeGenderValue,
.PersonCard-LastSeenValue {
    flex: 1;
}

.PersonCard-ConfidenceValue {
    flex: 2;
}