.Header {
    margin-top: 5vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-transform: uppercase;
    font-size: 2em;
    text-align: center;
}

.Header .Header-Active {
    font-weight: bold;
}

.Header h1 a {
    margin-right: 1em;
}

.Header h1 {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-weight: inherit;
    font-style: inherit;
    font-size: 100%;
    font-family: inherit;
    vertical-align: baseline;
}